<template>
  <div class="MtActivity">
    <van-row>
      <van-col span="12">
        <img :src="state.imgUrl" alt="">
      </van-col>
      <van-col span="12">span: 12</van-col>
    </van-row>
  </div>
</template>
<script>

export default {
  name: 'MtActivity',
  data(){
    return {
      state:{
        imgUrl:"https://s3plus.sankuai.com/v1/mss_5017c592a8a946d2a54eb62a76ba299c/nebulafile/3a201392201411cd294063da2671af32.png",
        title:"title",
        price:"price",
        vipPrice:"vipPrice",
        shopDesc:"shopDesc",
        delivery:"delivery",
        shopName:"shopName"
      }
    }
  }
}
</script>
